import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import poster from '../../images/dash_image.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { formattedDate, truncate } from "../../util/Util";
import { capitalize } from "../../util/Util";
import Endpoints from "../../util/Endpoints";

function EventCard({ event }) {
  const navigate = useNavigate();
  const [lowestPrice, setLowestPrice] = useState("");

  const handleClick = () => {
    window.scrollTo(0, 0);

    navigate(`/event/${event.eventid}/ticket`);
  };

  useEffect(() => {
    let price;
    if (event.eventpassed === "false") {
      let ticketData = event.tickettypes;

      if (event.eventpaying === "true") {
        if (ticketData && ticketData.length > 0) {
          const minPrice = ticketData.reduce((min, ticket) => {
            return ticket.ticketprice < min ? ticket.ticketprice : min;
          }, ticketData[0].ticketprice);
          price = `KES ${minPrice.toLocaleString()}`;
        }
      } else {
        price = "FREE";
      }
    } else {
      price = "Passed.";
    }

    setLowestPrice(price);
  }, [event]);

  return (
    <>     
    <div className=" group w-[340px] sm:w-60 md:w-[220px] lg:w-[274px] h-[450px] sm:h-[270px] md:h-[320px] lg:h-[440px] mb-4 ml-2 mr-2 overflow-hidden flex-shrink-0 bg-white border border-gray-200 rounded-lg  dark:border-gray-200 transition duration-500 hover:shadow-lg shadow-md" onClick={handleClick}>
        <div className="relative">
          <div className="h-[320px] sm:h-[140px] md:h-[180px] lg:h-[300px] w-full sm:w-60 md:w-[220px] lg:w-[274px]">
            <img
              className="w-full h-full  rounded-t-lg "
              src={`${Endpoints.SERVER_HOST}/eventpics/${event.eventid}_banner_pic`}
              alt="event poster"
            />
          </div>
          <div className="absolute top-0 left-0">
            <button 
            className={`px-4 py-2 font-roboto text-base font-semibold leading-6 rounded-tl-lg rounded-br-lg  text-white ${
              event.eventpassed === "false" ? 'bg-spaceCadet group-hover:bg-customOrange' : 'bg-yellowOrange'
          }`}
            >
              {lowestPrice}
            </button>
          </div>
        </div>

        <div className="p-2 flex flex-col space-y-1">
          <div className="float-left text-base text-gray-900 w-85">
            <div className="pl-1">
              <p className="block sm:hidden text-primaryBlue font-roboto font-medium text-[14px] leading-[28.5px] truncate">
              {event.eventname}
              </p>
              <p className="hidden sm:flex text-primaryBlue font-roboto font-medium text-[16px] ] leading-[28.5px] truncate">
                {event.eventname}
              </p>
            </div>
          </div>
          <div className="">
            <p className="flex flex-row space-x-3 pl-2">
              <span>
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  className="text-silverGray"
                />
              </span>
              <span className="font-roboto text-[11px] md:text-[13px] font-medium leading-relaxed text-customGrey pt-0.5">
                {formattedDate(event.eventstartdate)}
              </span>
            </p>
          </div>
          <div className="">
            <p className="flex flex-row space-x-3 pl-2">
              <span>
                <FontAwesomeIcon icon={faClock} className="text-silverGray" />
              </span>
              <span className="font-roboto text-[11px] md:text-[13px] font-medium leading-relaxed text-customGrey pt-0.5">
                {event.eventtime}
              </span>
            </p>
          </div>
          <div className="">
            <p className="flex flex-row space-x-3 pl-2.5">
              <span>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="text-silverGray"
                />
              </span>
              <span className="font-roboto text-[11px] md:text-[13px] font-medium leading-relaxed text-customGrey pt-0.5">
                {truncate(event.eventlocationname, 20)}
              </span>
            </p>
          </div>
        </div>
      </div>
     
    </>
  );
}

export default EventCard;
