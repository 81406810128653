import React from "react";
import EventTicket from "../pages/Events/EventTicket";
import FreeEventTicket from "../pages/Events/FreeEventTicket";
import eventSoldOut from "../images/soldoutevent.jpg";

function TicketTypesHandler({
  event,
  handleTicketChange,
  handleFreeTicketCountChange,
}) {
  let ticketTypes = null;

  if (event) {
    const eventSettings = JSON.parse(event.eventsettings);
    const ticketsPerTransaction = eventSettings.ticketspertransaction ?? 5;
    if (event.eventpaying === "true" || event.eventpaying === "false") {
      if (event.eventticketstatus === "available") {
        ticketTypes =
          event.eventpaying === "true" ? (
            event.tickettypes.map((ticket, index) => (
              <EventTicket
                key={ticket.id}
                ticket={ticket}
                index={index}
                handleTicketChange={handleTicketChange}
                ticketsPerTransaction={ticketsPerTransaction}
              />
            ))
          ) : (
            <FreeEventTicket
              event={event}
              onFreeTicketCountChange={handleFreeTicketCountChange}
              ticketsPerTransaction={ticketsPerTransaction}
            />
          );
      } else {
        ticketTypes = Array.from({ length: 3 }, (_, index) => (
          <img
            className="mt-20"
            key={`soldOut${index}`}
            src={eventSoldOut}
            alt="Event Sold Out"
          />
        ));
      }
    }
  }
  return (
    <>
      {event && event.eventpaying === "true" ? (
        <div className="h-auto p-1 lg:p-1 ">
          <h3 className="text-primaryBlue font-roboto font-semibold leading-6 text-base p-4">
            Tickets Available
          </h3>
          <div className="p-5">
            {/* <div className='flex justify-center'> */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6 xl:gap-8">
              {ticketTypes}
            </div>
            {/* </div> */}
          </div>
        </div>
      ) : event && event.eventpaying === "false" ? (
        <div className=" h-auto p-4 lg:p-1">
          <h3 className="text-primaryBlue font-roboto font-semibold leading-6 text-base">
            Register for Event
          </h3>
          <div>
            <div className=" grid grid-cols-2 gap-3 lg:grid lg:grid-cols-3 lg:gap-2">
              {ticketTypes}
            </div>
          </div>
        </div>
      ) : (
        <div className=" h-auto p-4 lg:p-1">
          <h3 className="text-primaryBlue font-roboto font-semibold leading-6 text-base">
            Tickets Available
          </h3>
          <div>
            <div className=" grid grid-cols-2 gap-3 lg:grid lg:grid-cols-3 lg:gap-2"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default TicketTypesHandler;
