import React, { useRef, useState } from "react";
import EventCard from "./EventCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function EventRow({ title, events, titleStyle }) {
  const eventListRef = useRef(null);
  const [scrolling, setScrolling] = useState(false);

  const scrollRight = () => {
    if (eventListRef.current) {
      eventListRef.current.scrollBy({
        left: 1000, // Adjust as needed
        behavior: "smooth",
      });
      setScrolling(true);
    }
  };

  const scrollLeft = () => {
    if (eventListRef.current) {
      eventListRef.current.scrollBy({
        left: -1000, // Adjust as needed
        behavior: "smooth",
      });
      setScrolling(true);
    }
  };

  const handleScroll = () => {
    if (eventListRef.current) {
      const { scrollLeft } = eventListRef.current;
      setScrolling(scrollLeft > 0);
    }
  };

  const eventList = events.map((event) => (
    <EventCard key={event.id} event={event} />
  ));

  return (
    <>
      <div className="flex flex-col mb-0 w-screen mx-auto md:max-w-3xl lg:max-w-7xl xl:max-w-7xl">
        <div className=" flex justify-between items-center mb-2 pl-4">
          <h2
            className="mb-2 text-primaryBlue text-[17px] md:text-[19px] font-bold"
            style={titleStyle}
          >
            {title}
          </h2>
          <div
            href="/events"
            className="text-primaryBlue hover:text-customOrange text-[12px] md:text-[14px] font-medium hover:underline"
            style={{ cursor: "pointer" }}
          >
            <NavLink to="/events">All Events</NavLink>
          </div>
        </div>
        <div className="flex flex-row">
          <div>
            {scrolling && events.length > 4 && (
              <FontAwesomeIcon
                icon={faArrowLeft}
                onClick={scrollLeft}
                className="text-primaryBlue cursor-pointer mt-48 ml-[-50px] top-64 hidden sm:flex"
              />
            )}
          </div>
          <div
            className="flex flex-row gap-4 overflow-x-auto ml-3 mr-5 lg:p-3 hide-scrollbar"
            ref={eventListRef}
            onScroll={handleScroll}
          >
            {eventList}
          </div>
          <div>
            {events.length > 4 && (
              <FontAwesomeIcon
                icon={faArrowRight}
                onClick={scrollRight}
                className="text-primaryBlue cursor-pointer mt-48  top-64 hidden sm:flex"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EventRow;
