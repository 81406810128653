import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { capitalize } from "../../util/Util";
import mpesa from "../../images/mpesa_card.svg";
import mpesa_clicked from "../../images/mpesa_card_clicked.svg";
import visa_clicked from "../../images/visa_card_clicked.svg";
import visa from "../../images/visa_card.svg";
import { useCheckoutMultiple } from "../../hooks/useCheckoutMultiple";
import { Helmet } from "react-helmet";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import GenderInput from "../../components/GenderInput";
import PaidAttendeeForm from "../../components/PaidAttendeeForm";
import MultiplePaidTicketSummary from "../../components/MultiplePaidTicketSummary";
import PaymentButton from "../../components/PaymentButton";

function EventCheckoutMultiple() {
  const location = useLocation();
  const {
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    age,
    setAge,
    company,
    setCompany,
    position,
    setPosition,
    postalAddress,
    setPostalAddress,
    officeNumber,
    setOfficeNumber,
    physicalAddress,
    setPhysicalAddress,
    gender,
    setGender,
    promoCode,
    setPromoCode,
    showInput,
    selectedImage,
    showMpesaInput,
    error,
    mpesaNumber,
    setMpesaNumber,
    promoError,
    promoMessage,
    promoApplied,
    totalAmount,
    open,
    setOpen,
    eventName,
    currency,
    dataNeeded,
    handlePaidTicketAttendeeInput,
    showHidePromoField,
    mpesaVisaSelection,
    ticketTypeArray,
    addTicketRecepient,
    removeTicketRecepient,
    checkPromoCode,
    buyMultiplePaidTickets,
  } = useCheckoutMultiple(location.state);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title> {capitalize(eventName[0])} | Checkout</title>
      </Helmet>
      <div className="mx-auto w-screen  mt-14 sm:mt-20 md:mt-20 lg:max-w-7xl pb-[40px]">
        <form onSubmit={buyMultiplePaidTickets}>
          <div className="flex flex-col md:flex-row pt-4 gap-6 lg:gap-24  md:justify-center">
            <div className="flex flex-col md:w-3/5 md:pl-8">
              <div className="flex flex-col gap-3 p-2">
                <p className="font-roboto font-bold text-[23px] leading-[150%] text-primaryBlue">
                  Checkout
                </p>
                {/* <div className="flex flex-row space-x-2 bg-infoGrey items-center justify-center h-10 w-[600px] rounded-lg">
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className=" text-infoBlue"
                  />
                  <p className="font-roboto text-sm font-normal leading-[150%] text-infoBlue">
                    We’ve reserved your tickets please complete checkout within
                    09:24 to secure your tickets.
                  </p>
                </div> */}
              </div>
              <div className="flex flex-col gap-3 p-2">
                <p className="font-roboto text-sm lg:text-base font-normal leading-[150%]">
                  Where should we send your booking confirmation?
                </p>

                <div className="grid gap-4 grid-cols-2 p-2">
                  <TextInput
                    label="Email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    required={true}
                    placeholder="ricky@gmail.com"
                  />
                  <TextInput
                    label="Phone Number"
                    id="phone"
                    value={phoneNumber}
                    onChange={(e) => {
                      const formattedNumber = e.target.value.replace(
                        /[^\d]/g,
                        ""
                      );
                      if (
                        formattedNumber.length === 10 &&
                        formattedNumber.startsWith("0")
                      ) {
                        const phoneNumberWithPrefix =
                          "254" + formattedNumber.substring(1);
                        setPhoneNumber(phoneNumberWithPrefix);
                      } else if (formattedNumber.length <= 12) {
                        setPhoneNumber(formattedNumber);
                      }
                    }}
                    type="tel"
                    required={true}
                    placeholder="254 700 000 000"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 p-2">
                <div className="flex flex-col lg:flex-row gap-2 md:gap-4">
                  <p className="font-roboto font-medium text-medium leading-[150%] text-primaryBlue">
                    Personal Details
                  </p>
                  <div className="flex flex-row space-x-2 bg-infoGrey items-center justify-center h-6 w-[340px] md:w-[300px] rounded-lg">
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className=" text-burntOrange"
                    />
                    <p className="font-roboto text-xs font-normal leading-4 text-burntOrange">
                      Please add your name to the section below.
                    </p>
                  </div>
                </div>

                <div className="grid gap-4 grid-cols-2 p-2">
                  {dataNeeded === "minimal" ||
                  dataNeeded === "basic" ||
                  dataNeeded === "depth" ? (
                    <>
                      <TextInput
                        label="First Name"
                        id="first_name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required={true}
                        placeholder="John"
                      />
                      <TextInput
                        label="Last Name"
                        id="last_name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required={true}
                        placeholder="Doe"
                      />
                      {(dataNeeded === "basic" || dataNeeded === "depth") && (
                        <>
                          <TextInput
                            label="Company"
                            id="company"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            required={true}
                            placeholder="Zynamis LTD"
                          />
                          <TextInput
                            label="Occupation"
                            id="position"
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                            required={true}
                            placeholder="Manager"
                          />
                          <SelectInput
                            label="Age"
                            id="age"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            options={[
                              { value: "", label: "Select an age range" },
                              { value: "1", label: "Less than 18" },
                              { value: "2", label: "18-25" },
                              { value: "3", label: "26-35" },
                              { value: "4", label: "36-45" },
                              { value: "5", label: "46+" },
                            ]}
                            required={true}
                          />
                          <GenderInput gender={gender} setGender={setGender} />
                        </>
                      )}
                      {dataNeeded === "depth" && (
                        <>
                          <TextInput
                            label="Postal Address"
                            id="postal_address"
                            value={postalAddress}
                            onChange={(e) => setPostalAddress(e.target.value)}
                            required={true}
                            placeholder="01000"
                          />
                          <TextInput
                            label="Physical Address"
                            id="physical_address"
                            value={physicalAddress}
                            onChange={(e) => setPhysicalAddress(e.target.value)}
                            required={true}
                            placeholder="Nairobi"
                          />
                          <TextInput
                            label="Office Number"
                            id="office_number"
                            value={officeNumber}
                            onChange={(e) => setOfficeNumber(e.target.value)}
                            required={true}
                            placeholder="B05B"
                          />
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col gap-2 p-2">
                <div className="flex flex-col gap-4">
                  <div
                    className="flex flex-row space-x-2 items-center cursor-pointer"
                    onClick={() => setOpen((prev) => !prev)}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="text-primaryBlue"
                      size="xs"
                    />
                    <p className="font-roboto font-medium text-sm md:text-base leading-[150%] text-primaryBlue">
                      Add Attendee Details
                    </p>
                  </div>
                  {open && (
                    <PaidAttendeeForm
                      open={open}
                      ticketTypeArray={ticketTypeArray}
                      addTicketRecepient={addTicketRecepient}
                      removeTicketRecepient={removeTicketRecepient}
                      handlePaidTicketAttendeeInput={
                        handlePaidTicketAttendeeInput
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-5 md:w-2/5">
              <MultiplePaidTicketSummary
                eventName={eventName}
                showInput={showInput}
                promoMessage={promoMessage}
                promoError={promoError}
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                promoApplied={promoApplied}
                currency={currency}
                showHidePromoField={showHidePromoField}
                checkPromoCode={checkPromoCode}
                ticketTypeArray={ticketTypeArray}
                totalAmount={totalAmount}
              />
              <div className="flex flex-col gap-4 p-2">
                <p className="font-roboto font-medium text-base leading-[150%] text-primaryBlue">
                  Select payment option
                </p>
                {error ? (
                  <p className="font-roboto font-normal text-sm leading-[150%] text-rose-700 pl-28">
                    {error}
                  </p>
                ) : null}
                <div className="flex flex-row gap-6 md:gap-8">
                  <img
                    src={selectedImage === "mpesa" ? mpesa_clicked : mpesa}
                    alt="mpesa"
                    onClick={() => mpesaVisaSelection("mpesa")}
                    className="rounded-lg"
                  />

                  <img
                    src={selectedImage === "visa" ? visa_clicked : visa}
                    alt="visa"
                    onClick={() => mpesaVisaSelection("visa")}
                    className="rounded-lg"
                  />
                </div>
              </div>
              <div className="p-3">
                {showMpesaInput && (
                  <TextInput
                    label="Mpesa Number"
                    id="mpesa_number"
                    value={mpesaNumber}
                    onChange={(e) => {
                      const formattedNumber = e.target.value.replace(
                        /[^\d]/g,
                        ""
                      );
                      if (
                        formattedNumber.length === 10 &&
                        formattedNumber.startsWith("0")
                      ) {
                        const phoneNumberWithPrefix =
                          "254" + formattedNumber.substring(1);
                        setMpesaNumber(phoneNumberWithPrefix);
                      } else if (formattedNumber.length <= 12) {
                        setMpesaNumber(formattedNumber);
                      }
                    }}
                    required={true}
                    placeholder="254 700 000 000"
                  />
                )}
              </div>
              <div>
                <PaymentButton label="Complete Payment" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default EventCheckoutMultiple;
