import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleLeft,
  faAngleRight,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEvents } from "../hooks/useEvents";
import { NavLink} from "react-router-dom";
import { formattedDate, truncate } from "../util/Util";
import Endpoints from "../util/Endpoints";
import "../index.css";
import EventInfo from "./EventInfo";
import CarouselButton from "./CarouselButton";

function Carousel() {
  const { popularEvents } = useEvents();

  const isPrerendering = navigator.userAgent === 'ReactSnap'; 

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow ${className}`}>
        <FontAwesomeIcon
          className="arrows"
          style={{ color: "#1E265B" }}
          icon={faAngleRight}
        />
      </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow ${className}`}>
        <FontAwesomeIcon
          className="arrows"
          style={{ color: "#1E265B" }}
          icon={faAngleLeft}
        />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    lazyLoad: true,
    nextArrow: <SampleNextArrow to="next" />,
    prevArrow: <SamplePrevArrow to="prev" />,
    className: "slides",
  };


  if (isPrerendering) {
    // Return a placeholder or static content during prerendering
    return (
      <div className="w-screen max-w-screen-2xl mx-auto flex items-center justify-center pt-6 lg:pt-10">
        <div className="w-full h-full max-w-screen-xl flex items-center justify-center">
          <p>Loading...</p> {/* or any other static content */}
        </div>
      </div>
    );
  }


  return (
    <>
      <div className="relative w-screen max-w-screen-2xl mx-auto flex items-center justify-center pt-6 lg:pt-10">
        <Slider {...settings} className="w-full h-full max-w-screen-xl">
          {popularEvents.map((event, index) => (
            <div key={index} className="w-full h-full">
              <div className="grid grid-cols-1 sm:grid-cols-2 mx-auto gap-3">
                <div className="flex w-full items-center justify-center">
                  <NavLink to={`/event/${event.eventid}/ticket`} className="">
                    <div className="w-72 h-72 lg:w-96 lg:h-96 flex items-center justify-center">
                      <img
                        src={`${Endpoints.SERVER_HOST}/eventpics/${event.eventid}_banner_pic`}
                        alt="event poster "
                        className="rounded-lg w-full h-full"
                      />
                    </div>
                  </NavLink>
                </div>
                <div className="flex flex-col sm:gap-2 lg:gap-4 justify-center items-center sm:items-start">
                 
                  <div className="">
                    <p className="text-primaryBlue font-roboto font-semibold text-[16px]  md:text-[28px] overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {(truncate(event.eventname, 39))}
                    </p>
                  </div>
                  <div className="p-1 flex flex-col gap-1 sm:gap-2 lg:gap-4">
                    <EventInfo
                      icon={faCalendarDays}
                      text={event.eventstartdate}
                      formatter={formattedDate}
                    />
                    <EventInfo icon={faClock} text={event.eventtime} />
                    <div className="ml-0.5">
                      <EventInfo
                        icon={faLocationDot}
                        text={event.eventlocationname}
                        formatter={(location) => truncate(location, 30)}
                      />
                    </div>
                  </div>
                  <div className="w-11/12 hidden sm:flex overflow-hidden sm:h-28 md:h-20 lg:h-36">
                    <p className="text-secondaryBlack font-roboto text-[11px] sm:text-[14px] font-normal leading-relaxed flex  justify-start">
                      {event.eventdescription}
                    </p>
                  </div>
                  <div className="mx-auto w-screen p-2 justify-center flex sm:block">
                    <CarouselButton event={event} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default Carousel;
