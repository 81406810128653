import React from 'react'
import qr_code from '../../images/qr_gig.svg'
import playstore from '../../images/plasytore-image.png'
import applestore from '../../images/applestore-image.png'
import apple_android from '../../images/android-apple.svg'
import Strings from '../../util/Strings'


function EventLink() {
  return (
    <>
      <div className='event-gradient h-[80px] mx-auto max-w-sm sm:max-w-lg md:max-w-3xl lg:max-w-4xl xl:max-w-6xl sm:items-stretch sm:justify-start sm:h-[100px] '>
        <div className='flex flex-row items-center justify-center space-x-5'>
          <div className='flex flex-shrink-0 sm:p-2.5'>
            <img className='hidden lg:flex h-20' src={qr_code} alt='playstore-qr-code' />
          </div>
          <div className='flex flex-col'>
            <p className='text-white font-roboto text-[11px] md:text-base font-normal leading-6 block md:hidden'>{Strings.APP_DOWNLOAD_DESCRIPTION_SMALL}</p>
            <p className='text-white font-roboto text-[11px] md:text-base font-normal leading-6 hidden md:flex'>{Strings.APP_DOWNLOAD_DESCRIPTION}</p>
            <div className='flex flex-row p-1 space-x-6'>
              <a href={Strings.PLAYSTORE_LINK}  target="_blank"  rel="noreferrer">
                <img src={playstore} alt='Gig GooglePlay' />
              </a>
              <a href={Strings.APPSTORE_LINK} target="_blank"  rel="noreferrer">
                <img src={applestore} alt='Gig AppleStore' />
              </a>
            </div>
          </div>
          <div className='hidden sm:flex sm:flex-row space-x-4 p-2'>
              <img src={apple_android} alt="Android & Phone"/>
          </div>

        </div>
      </div>
    </>
  )
}

export default EventLink
