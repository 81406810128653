import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import EventTicketSkeleton from "./EventTicketSkeleton";
import { ticketColours } from "../../util/Util";

const EventDetailsSkeleton = () => (
  <div className="mx-auto w-screen mt-14 sm:mt-20 md:mt-20 lg:max-w-7xl">
    <div className="w-full h-auto flex flex-col sm:flex-row p-2 gap-2 md:gap-8 justify-cente">
      <div className="mx-auto">
        <div className=" lg:p-4 flex flex-col w-full space-y-2 lg:space-y-3">
          <div className="">
            <div className="p-1">
              <div className="bg-gray-300 animate-pulse w-3/4 h-6 rounded-md"></div>
            </div>
          </div>
          <div>
            <div className="p-1">
              <div className="flex flex-row gap-4">
                <span>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    size="lg"
                    className="text-customGrey"
                  />
                </span>
                <span className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></span>
              </div>
            </div>
            <div className="p-1">
              <div className="flex flex-row gap-3.5">
                <span>
                  <FontAwesomeIcon
                    icon={faClock}
                    size="lg"
                    className="text-customGrey"
                  />
                </span>
                <span className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></span>
              </div>
              <div className="p-1 mt-1">
                <div className="flex flex-row gap-4">
                  <span>
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      size="lg"
                      className="text-customGrey"
                    />
                  </span>
                  <span className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-gray-300 animate-pulse rounded-lg md:min-h-full md:max-w-md h-[340px] w-[340px] sm:h-[320px] sm:w-[320px] md:w-[360px] md:h-[500px] lg:w-[420px] lg:h-[550px] p-1"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:space-y-4 md:w-1/2 mx-auto">
        <div className="flex flex-col space-y-2 lg:w-11/12 xl:w-full mt-2 p-5">
          <div className="flex flex-col lg:flex md:flex-col md:space-y-2">
          <div className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></div>
          <div className="bg-gray-300 animate-pulse w-full h-[60px] p-4 lg:p-1 rounded-md"></div>
          </div>          
        </div>
        <div className="h-auto p-1 lg:p-1">
          <div className="bg-gray-300 animate-pulse w-40 h-6 rounded-md"></div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-1 lg:gap-1 xl:gap-1">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="w-1/3 mb-4">
               <EventTicketSkeleton color={ticketColours[index % ticketColours.length]} />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col space-y-4 p-5 lg:p-1">
          <div className="bg-gray-300 animate-pulse w-full h-12 rounded-lg"></div>
          <button
            disabled
            className="bg-secondaryOrange text-white hover:text-white font-bold py-2 px-6 rounded-lg text-base w-full"
          >
            Continue To Check Out
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default EventDetailsSkeleton;
