import React, { useEffect } from "react";
import { useEvents } from "../../hooks/useEvents";
import { useParams } from "react-router-dom";
import EventDetailsSkeleton from "./EventDetailsSkeleton";
import EventRowSkeleton from "./EventRowSkeleton";
import EventRow from "./EventRow";
import { useEventDetails } from "../../hooks/useEventDetails";
import TicketTypesHandler from "../../handler/TicketTypesHandler";
import EventDetailsHandler from "../../handler/EventDetailsHandler";
import CheckoutHandler from "../../handler/CheckoutHandler";
import AboutEventHandler from "../../handler/AboutEventHandler";
import TotalAmountHandler from "../../handler/TotalAmountHandler";
import { setMetaTag } from "../../util/Util";
import EventMetaTags from "./EventMetaTags";

function EventDetails() {
  const { event, isLoading, popularEvents, handleEventClick } = useEvents();
  const id = useParams();
  const baseUrl = window.location.origin;

  const {
    setRefValue,
    freeTicketCount,
    handleFreeTicketCountChange,
    handleTicketChange,
    totalAmount,
    freeEventData,
    ticketTypeCountArray,
    ticketTypeCount,
  } = useEventDetails(event);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const promoParam = urlParams.get("ref");
    if (promoParam) {
      setRefValue(promoParam);
    }
  }, [setRefValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleEventClick(id);
  }, [id]);

  useEffect(() => {
    if (event) {
      document.title = `${event.eventname} | Details`;
      setMetaTag([
        {
          property: "og:url",
          content: `${baseUrl}/event/${event.eventid}/ticket`,
        },
        {
          property: "og:title",
          content: `${event.eventname} | ${event.eventcategory}`,
        },
        {
          property: "og:type",
          content: `article`,
        },
        {
          property: "og:description",
          content: `${event.eventdescription} | Event Tickets in Kenya and Nairobi`,
        },
        {
          property: "og:image",
          content: `https://client.gig.co.ke/eventpics/${event.eventid}_banner_pic`,
        },
      ]);
    }

    return () => {
      const dynamicMetaTags = document.querySelectorAll(
        'meta[data-dynamic="true"]'
      );
      dynamicMetaTags.forEach((tag) => tag.parentNode.removeChild(tag));
    };
  }, [event]);

  return (
    <>
      <div>
        <EventMetaTags event={event} />
        <div className="flex flex-col max-w-screen-2xl mx-auto">
          <div>
            {isLoading ? (
              <EventDetailsSkeleton />
            ) : (
              <>
                <div className="mx-auto w-screen mt-14 sm:mt-20 md:mt-20 lg:max-w-7xl">
                  <div className="w-full h-auto flex flex-col sm:flex-row p-2 gap-2 md:gap-8 justify-center">
                    <div className="mx-auto">
                      <EventDetailsHandler event={event} />
                    </div>
                    <div className="flex flex-col space-y-2 md:space-y-4 md:w-1/2 mx-auto">
                      <div>
                        <AboutEventHandler event={event} />
                      </div>
                      <div>
                        <TicketTypesHandler
                          event={event}
                          handleTicketChange={handleTicketChange}
                          handleFreeTicketCountChange={
                            handleFreeTicketCountChange
                          }
                        />
                      </div>
                      <div className="flex flex-col space-y-4 p-5 lg:p-1 ">
                        <div>
                          <TotalAmountHandler
                            event={event}
                            totalAmount={totalAmount}
                          />
                        </div>
                        <div>
                          <CheckoutHandler
                            event={event}
                            ticketTypeCount={ticketTypeCount}
                            ticketTypeCountArray={ticketTypeCountArray}
                            freeEventData={freeEventData}
                            freeTicketCount={freeTicketCount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="max-w-screen-2xl mt-8 pt-[40px]  pb-[40px]">
            {isLoading ? (
              <div
                className={`skeleton-wrapper ${
                  isLoading ? "" : "skeleton-hidden"
                }`}
              >
                <EventRowSkeleton />
              </div>
            ) : (
              <div
                className={`skeleton-wrapper ${
                  isLoading ? "skeleton-hidden" : ""
                }`}
              >
                <div
                  className={`event-row ${
                    isLoading ? "" : "event-row-visible"
                  }`}
                >
                  <EventRow
                    title="Related Events"
                    events={popularEvents}
                    titleStyle={{ marginLeft: "20px" }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EventDetails;
