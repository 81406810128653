import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";

function EventTicketSkeleton() {
  return (
    <>
      <div className="w-[260px] lg:w-[240px] h-[180px] xl:w-[260px] rounded-lg mt-2 flex flex-col  border-[1.7px] border-gray-300">
        <div className="p-5 w-full h-full  items-start space-y-2">
        <div className="bg-gray-300 h-7 w-full rounded-md animate-pulse"></div>
        <div className="bg-gray-300 h-7 w-full rounded-md animate-pulse"></div>
        <div className="flex flex-row space-x-2 ml-1 w-full">
          <IoIosInformationCircleOutline className="text-deepBlue mt-1 hover:text-customGrey w-[20px] h-[20px]" />
          <div className="bg-gray-300 h-5 w-32 mt-0.5 rounded-md animate-pulse"></div>
        </div>
        <hr className="bg-primaryBlack w-[calc(100% + 2.5rem)] -ml-5 -mr-5 h-0.5" />
        <div className="flex flex-row justify-between items-center w-full">
          <button className="rounded-md bg-primaryBlack h-[35px] w-[35px] mt-1.5 text-white flex justify-center items-center">
            <FontAwesomeIcon icon={faMinus} className="w-[30px] h-[16px] " inverse />
          </button>
          <div className="bg-gray-300 h-5 mt-1 w-6 rounded-lg"></div>
          <button
            className={`rounded-md bg-primaryBlack h-[35px] w-[35px] mt-1.5 text-white flex justify-center items-center`}
          >
            <FontAwesomeIcon icon={faPlus} className="w-[30px] h-[16px]" inverse />
          </button>
        </div>
        </div>
       
      </div>
    </>
  );
}

export default EventTicketSkeleton;
